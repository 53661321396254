<template>
  <div id="AboutPage" class="d-flex justify-content-center">
    <div class="d-flex flex-column w-50">
      <b-card no-body class="w-100 overflow-hidden">
        <div class="d-flex justify-content-between flex-wrap">
          <b-table
            responsive
            striped
            :items="platformConfiguration"
            :fields="[
              { key: 'property' },
              { key: 'value' },
            ]"
            class="position-relative"
          />
        </div>
      </b-card>

      <b-card-actions
        ref="serviceStatusCard"
        title="Kompozition Service Status"
        action-refresh
        :show-loading="loading"
        class="w-100 overflow-hidden"
        @refresh="refreshServiceStatusList"
      >
        <b-list-group flush>
          <b-list-group-item
            v-for="api in apiList"
            :key="api.name"
            class="d-flex justify-content-between align-items-center"
          >
            {{ api.name }}
            <b-badge
              :variant="statusVariant(api.status)"
              class="status-badge text-uppercase"
              :class="api.status === 'error' ? 'animate-pulse' : ''"
            >
              {{ api.status }}
            </b-badge>
          </b-list-group-item>
        </b-list-group>
      </b-card-actions>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api'
import getEnv from '@/utils/env'
import coreService from '@/libs/api-services/core-service'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

export default {
  name: 'AboutPage',
  components: { BCardActions },
  setup(props, context) {
    onMounted(() => { refreshServiceStatusList() })
    const platformConfiguration = [
      { property: 'Release version', value: getEnv('VUE_APP_KOMPOZITION_RELEASE') },
      { property: 'Release date', value: context.root.$options.filters.formatDate(getEnv('VUE_APP_KOMPOZITION_BUILD_DATE')) },
      { property: 'Build details', value: getEnv('VUE_APP_KOMPOZITION_BUILD_COMMIT') },
      { property: 'Build agent', value: `${getEnv('VUE_APP_KOMPOZITION_BUILD_AGENT')} (job id: ${getEnv('VUE_APP_KOMPOZITION_BUILD_JOB_ID')})` },
      { property: 'Loging level', value: process.env.VUE_APP_LOGGING_LEVEL },
      { property: 'Sentry.io monitoring enabled', value: getEnv('VUE_APP_SENTRY_ENABLED') },
    ]

    const statusVariant = status => {
      switch (status) {
      case 'online':
        return 'success'
      case 'error':
        return 'danger'
      case 'loading':
        return 'warning'
      default:
        return 'secondary'
      }
    }
    const apiList = ref([
      { name: 'Legacy API Service', status: 'unknown' },
      { name: 'Core API Service', status: 'unknown' },
      { name: 'Authentication Service', status: 'unknown' },
      { name: 'Natural Language Parsing Service', status: 'unknown' },
      { name: 'Worker Service', status: 'unknown' },
    ])

    const serviceStatusCard = ref(null)
    const loading = ref(false)
    const refreshServiceStatusList = async () => {
      serviceStatusCard.value.showLoading = true
      await testLegacyApiService()
      await testCoreApiService()
      await testAuthenticationService()
      // TODO await testNlpService()
      // TODO await testWorkerService()
      serviceStatusCard.value.showLoading = false
    }

    const testLegacyApiService = async () => {
      await coreService.get('/v1/legacy/auth/ping')
        .then(response => { apiList.value[0].status = 'online' })
        .catch(error => { apiList.value[0].status = 'error' })
    }
    const testCoreApiService = async () => {
      await coreService.get('/utils/ping')
        .then(response => { apiList.value[1].status = 'online' })
        .catch(error => { apiList.value[1].status = 'error' })
    }
    const testAuthenticationService = async () => {
      await coreService.get('/v1/legacy/auth/auth_test')
        .then(response => { apiList.value[2].status = 'online' })
        .catch(error => { apiList.value[2].status = 'error' })
    }

    return {
      platformConfiguration,

      serviceStatusCard,
      loading,
      apiList,
      refreshServiceStatusList,
      statusVariant,
    }
  },
}
</script>
